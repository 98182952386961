
import { defineComponent, defineAsyncComponent } from 'vue';
import BaseLayout from '@/components/layout/BaseLayout.vue';
import AuthView from '@/views/auth/AuthView.vue';
import MobileDummy from '@/views/MobileDummy.vue';
import GoodsSelector from '@/components/goodsSelector/GoodsSelector.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { useIsMobile } from '@/composables/useIsMobile';
import ResetPasswordView from '@/views/reset-password/ResetPasswordView.vue';
import Messages from '@/components/messages/Messages.vue';
import UserAgreement from '@/views/user-agreement/UserAgreement.vue';
import GoodInfo = Models.Good.GoodInfo;

interface ComponentData {
  showOnboarding: boolean;
}

export default defineComponent({
  name: 'App',

  components: {
    Messages,
    ResetPasswordView,
    BaseLayout,
    AuthView,
    MobileDummy,
    GoodsSelector,
    UserAgreement,
    Onboarding: defineAsyncComponent(() => import('@/components/onboarding/Onboarding.vue')),
  },

  setup() {
    const { isMobile, observeResize } = useIsMobile();

    return {
      isMobile,
      observeResize,
    };
  },

  mounted() {
    this.observeResize(true);
    this.start();
  },

  computed: {
    ...mapState('user', ['info', 'token']),
    ...mapGetters('message', ['messages']),
    ...mapGetters('filter', {
      showGoodsSelector: 'showGoodsSelector',
      goods: 'goods',
    }),
  },

  data(): ComponentData {
    return {
      showOnboarding: true,
    };
  },

  methods: {
    ...mapActions(['start']),
    ...mapActions('filter', {
      showFilterAction: 'showFilter',
      showGoodsSelectorAction: 'showGoodsSelector',
      recordGoodsAction: 'goods',
    }),

    closeGoodsSelector() {
      this.showGoodsSelectorAction(false);
      this.showFilterAction(true);
    },

    goodSelectorResult(goods: GoodInfo[]): void {
      if (this.$route.name === 'Sales') {
        if (goods.length === 1) {
          this.$store.dispatch(
            'message/add',
            {
              status: 'info',
              text: 'Доступен выбор периода сроком до 5 лет',
            },
          );
        }
        if (goods.length > 1) {
          this.$store.dispatch(
            'message/add',
            {
              status: 'info',
              text: 'Доступен выбор периода сроком до 3 месяцев',
            },
          );
        }
      }
      this.recordGoodsAction(goods);
    },
  },
});
